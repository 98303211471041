<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config" />
    <nav>
      <span
        @click="
          form.status = '全部';
          form.page = 1;
          $forceUpdate();
          getData(form);
        "
        :class="form.status == '全部' ? 'active' : ''"
        ><span class="label">{{ $fanyi("全部") }}</span
        ><span class="numberCount">{{ tableTypeNum[5].num }}</span></span
      >
      <span
        @click="
          form.status = '待发货配送单';
          form.page = 1;
          $forceUpdate();
          getData();
        "
        :class="form.status == '待发货配送单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("待发货") }}</span
        ><span class="numberCount">{{ tableTypeNum[0].num }}</span></span
      >
      <span
        @click="
          form.status = '运输中配送单';
          form.page = 1;
          $forceUpdate();
          getData();
        "
        :class="form.status == '运输中配送单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("运输中") }}</span
        ><span class="numberCount">{{ tableTypeNum[1].num }}</span></span
      >
      <span
        @click="
          form.status = '到达RAKUMART运输中心配送单';
          form.page = 1;
          $forceUpdate();
          getData();
        "
        :class="form.status == '到达RAKUMART运输中心配送单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("到达RAKUMART物流中心") }}</span
        ><span class="numberCount">{{ tableTypeNum[2].num }}</span></span
      >
      <span
        @click="
          form.status = '西班牙派送配送单';
          form.page = 1;
          $forceUpdate();
          getData();
        "
        :class="form.status == '西班牙派送配送单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("西班牙派送") }}</span
        ><span class="numberCount">{{ tableTypeNum[3].num }}</span></span
      >
      <span
        @click="
          form.status = '已签收配送单';
          form.page = 1;
          $forceUpdate();
          getData();
        "
        :class="form.status == '已签收配送单' ? 'active' : ''"
        ><span class="label">{{ $fanyi("已签收") }}</span
        ><span class="numberCount">{{ tableTypeNum[4].num }}</span></span
      >
    </nav>
    <div>
      <div class="searchBox">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input
            type="text"
            v-model="form.searchText"
            @keydown.enter="getData()"
            :placeholder="$fanyi('请输入关键词搜索')"
          />
        </div>
        <button
          class="searchBtn"
          @click="
            form.page = 1;
            getData(form);
          "
        >
          {{ $fanyi("搜索") }}
        </button>
      </div>
      <!-- 表格 -->
      <div class="dataBody">
        <div class="myTable">
          <ol class="tableHead">
            <li class="bushu">{{ $fanyi("部署") }}</li>
            <li class="flex07">Nº</li>
            <li>{{ $fanyi("照片") }}</li>
            <li class="flex15">{{ $fanyi("描述") }}</li>
            <li>{{ $fanyi("数量") }}</li>
            <li>{{ $fanyi("小计") }}</li>
            <li>{{ $fanyi("状态") }}</li>
            <li class="flex15">{{ $fanyi("国际追踪号码") }}</li>
            <!-- <li class="flex15">{{ $fanyi("操作") }}</li> -->
          </ol>

          <div class="tableBody">
            <div
              class="tableBodyOpt"
              v-for="(tableDataItem, tableIndex) in tableData"
              :key="tableIndex"
            >
              <!-- 订单数据行 -->

              <div class="serialNumber">
                <div class="order">
                  <div class="img">
                    <img
                      @click="
                        tableDataItem.showChildData = !tableDataItem.showChildData;
                        $forceUpdate();
                      "
                      v-bind:class="{
                        openIcon: tableDataItem.showChildData == true,
                      }"
                      src="../../../../assets/user-img/blueIcon.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="order_sn">
                  <p>
                    <span>{{ $fanyi("订单号") }}：</span>
                    <button
                      @click="
                        $fun.routerToPage(
                          '/OrderDetails?type=purchase&order_sn=' +
                            tableDataItem.porder_detail[0].order_sn,
                          true
                        )
                      "
                    >
                      {{ tableDataItem.porder_detail[0].order_sn }}
                    </button>
                  </p>
                  <p>
                    <span>{{ $fanyi("配送单号") }}：</span>
                    <button
                      @click="
                        $fun.routerToPage(
                          '/deliveryDetails?type=purchase&order_sn=' +
                            tableDataItem.porder_sn
                        )
                      "
                    >
                      {{ tableDataItem.porder_sn }}
                    </button>
                  </p>
                </div>
                <div class="item">
                  {{ tableDataItem.totalNum }}
                </div>
                <div class="item">
                  {{
                    $fun.ceil(
                      tableDataItem.totalPrice * tableDataItem.exchange_rate
                    )
                  }}
                  €
                </div>
                <div class="item">
                  {{ $fanyi(tableDataItem.statusName) }}
                </div>
                <!-- <div class="payBtn item">
                  <span
                    v-if="
                      ['已发货', '西班牙国内配送中'].includes(
                        tableDataItem.statusName
                      )
                    "
                    @click="qianShou(tableDataItem.porder_sn)"
                  >
                    {{ $fanyi("确认签收") }}</span
                  >
                  
                </div> -->
                <div class="item internationalLogistics">
                  <el-popover
                    placement="bottom-end"
                    width="324"
                    trigger="click"
                  >
                    <div
                      class="logisticsnoDataBox"
                      v-if="
                        (!(tableDataItem.logisticsData || {}).trace ||
                          (
                            (tableDataItem.logisticsData || {}) &&
                            !(tableDataItem.logisticsData || {}).trace
                          ).length) &&
                          !logisticsLoading
                      "
                    >
                      {{ $fanyi("暂无数据") }}
                    </div>
                    <div
                      v-else
                      class="logisticsInformationBox"
                      v-loading="logisticsLoading"
                    >
                      <div class="rightConBox">
                        <div class="leftBorderBox"></div>
                        <div
                          class="logoptBOx"
                          v-for="(logitem, logindex) in (
                            tableDataItem.logisticsData || {}
                          ).trace"
                          :key="logindex"
                        >
                          <div class="actLogistics" v-if="logindex == 0">
                            <img
                              :src="require('@/assets/icon/wuliuAct.png')"
                              alt=""
                            />
                          </div>
                          <div class="actLogistics normalIcon" v-else>
                            <img
                              :src="require('@/assets/icon/wuliumoren.png')"
                              alt=""
                            />
                          </div>
                          <div class="logisticsFontBox">
                            <div class="logisticsTitle">
                              {{ logitem.msg }}
                            </div>
                            <div class="logisticsCon">
                              <div class="wuliuDateIcon">
                                <img
                                  :src="
                                    require('@/assets/icon/wuliushijian.png')
                                  "
                                  alt=""
                                />
                              </div>
                              <div class="wuliuDateFont">
                                {{ logitem.datetime }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      slot="reference"
                      style="cursor: pointer;"
                      class="expressnoCon"
                      @click="getlogisticsInformation(tableDataItem)"
                    >
                      {{ tableDataItem.express_no }}
                    </div>
                  </el-popover>
                </div>
              </div>
              <ul
                v-for="(goodsItem, goodsIndex) in tableDataItem.porder_detail"
                :key="goodsIndex"
                v-show="tableDataItem.showChildData"
              >
                <li class="ml bushu"></li>
                <li class="flex07">{{ goodsIndex + 1 }}</li>
                <li>
                  <el-popover placement="right" trigger="hover">
                    <img
                      :src="goodsItem.order_detail.pic"
                      alt=""
                      style="width: 300px; height: 300px; cursor: pointer"
                    />

                    <el-image
                      :src="goodsItem.order_detail.pic"
                      @click="
                        $fun.toCommodityDetails(goodsItem.order_detail.goods_id)
                      "
                      style="width: 60px; height: 60px; cursor: pointer"
                      slot="reference"
                    >
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                </li>
                <li class="flex15 goodsDetail" style="overflow: scroll">
                  <div>
                    <p
                      v-for="(item, index) in goodsItem.order_detail.detail"
                      :key="index"
                      :title="item.key + ':' + item.value"
                    >
                      <span class="detailKey">{{ item.key }}</span>
                      ：
                      <span class="detailValue">{{ item.value }}</span>
                    </p>
                  </div>
                </li>

                <li>
                  {{ $fun.RMBNumSegmentation(goodsItem.order_detail.num) }}
                </li>
                <li>
                  {{
                    $fun.RMBNumSegmentation(
                      $fun.ceil(
                        goodsItem.order_detail.num *
                          goodsItem.order_detail.price *
                          tableDataItem.exchange_rate
                      )
                    )
                  }}
                  €
                </li>
                <li>- -</li>
                <li class="flex15">- -</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="allpag">
        <a
          href="javascript:;"
          @click="
            form.page = 1;
            getData();
          "
          >{{ $fanyi("回到首页") }}</a
        >
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="form.page"
          :page-size="10"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from "../../../../components/public/Dialog.vue";
import { daysDistance, getCurrentDay } from "../../../../utlis/date";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      getCurrentDay,
      daysDistance,
      form: {
        status: "全部",
        page: 1,
        pageSize: 10,
        searchText: "",
      },
      logisticsLoading: false,
      config: {
        top: "20vh",
        width: "500px",
        title: this.$fanyi("提示"),
        btnTxt: [this.$fanyi("确定"), this.$fanyi("取消")],
      },
      tableData: [],
      total: 0,
      tableTypeNum: [
        {
          status: "待发货配送单",
          num: 0,
        },
        {
          status: "运输中配送单",
          num: 0,
        },
        {
          status: "到达RAKUMART运输中心配送单",
          num: 0,
        },
        {
          status: "西班牙派送配送单",
          num: 0,
        },
        {
          status: "已签收配送单",
          num: 0,
        },
        {
          status: "全部状态",
          num: 0,
        },
      ],
    };
  },
  computed: {},

  created() {
    if (this.$route.query.status) {
      // this.form.status = this.$route.query.status;
      switch (this.$route.query.status) {
        case "orderForDelivery":
          this.form.status = "待发货配送单";
          break;
        case "deliveryInTransit":
          this.form.status = "运输中配送单";
          break;
        case "deliveryFormToRAKUMARTTransportCentre":
          this.form.status = "到达RAKUMART运输中心配送单";
          break;
        case "spanishDeliveryOrder":
          this.form.status = "西班牙派送配送单";
          break;
        case "internationalLogistics":
          this.form.status = "已签收配送单";
          break;
        default:
          this.form.status = this.$route.query.status;
          break;
      }
    }
    if (this.$route.query.type) {
      this.form.status = this.$fun.fromCode(this.$route.query.type);
    }
    this.$forceUpdate();
    this.getData();
    this.getTitleNumber();
  },
  methods: {
    // 获取物流跟踪信息
    getlogisticsInformation(tableDataItem) {
      this.logisticsLoading = true;
      tableDataItem.logisticsData = {};
      this.$api
        .getPorderExpressTrace({ express_no: tableDataItem.express_no })
        .then((res) => {
          ////console.log('事件名',res)
          this.logisticsLoading = false;
          if (res.code != 0 || res.data == [] || res.data == null) return;
          //接下来的操作
          tableDataItem.logisticsData = res.data;
          this.$forceUpdate();
        });
    },
    // 获取种类数量数据
    getTitleNumber() {
      this.$api.logisticsOrderCountNum().then((res) => {
        if (res.code != 0) return false;
        if (res.data.length == 0) return false;
        res.data.forEach((item) => {
          switch (item.status) {
            case "待发货配送单":
              this.tableTypeNum[0].num = item.num;
              break;
            case "运输中配送单":
              this.tableTypeNum[1].num = item.num;
              break;
            case "到达RAKUMART运输中心配送单":
              this.tableTypeNum[2].num = item.num;
              break;
            case "西班牙派送配送单":
              this.tableTypeNum[3].num = item.num;
              break;
            case "已签收配送单":
              this.tableTypeNum[4].num = item.num;
              break;
            case "全部状态":
              this.tableTypeNum[5].num = item.num;
              break;
          }
        });
        this.tableTypeNum = res.data;
      });
    },
    // 获取数据
    getData() {
      let datas = {
        status: this.form.status,
        pageSize: this.form.pageSize,
        page: this.form.page,
        searchText: this.form.searchText,
      };
      this.$api.logisticsOrderList(datas).then((res) => {
        if (res.code != 0) return false;
        res.data.data.forEach((item) => {
          item.porder_detail.forEach((items) => {
            items.order_detail_list = [];
            items.order_detail.detail = JSON.parse(items.order_detail.detail);
            items.order_detail_list.push(items.order_detail);
          });
        });
        this.tableData = res.data.data;
        this.dataProcessing(this.tableData);
        this.total = res.data.total;
      });
    },
    //派送中配送单签收
    qianShou(sn) {
      this.$refs.dialog.open(
        this.$fanyi("是否签收选中的商品？"),
        () => {
          this.$api
            .internationalLogisticsClientReceive({ porder_sn: sn })
            .then((res) => {
              //console.log("internationalLogisticsClientReceive", res);
              if (res.code != 0) return;
              this.$message.success(this.$fanyi("签收成功"));
              this.getData();
              this.getTitleNumber();
            });
        },
        () => {
          this.$message(this.$fanyi("已取消签收"));
        }
      );
    },
    // 数据处理
    dataProcessing(data) {
      data.forEach((dataItem) => {
        // 添加是否显示子数据的参数
        dataItem.showChildData = false;
        // 订单商品数量
        dataItem.orderNum = 0;
        //订单内商品数量总计
        dataItem.totalNum = 0;
        // 订单小计
        dataItem.totalPrice = 0;
        // 国内运费
        dataItem.confirm_freight = 0;
        dataItem.porder_detail.forEach((item) => {
          item.order_detail_list.forEach((goodsItem) => {
            dataItem.orderNum += goodsItem.num;
            // 商品小计计算
            goodsItem.totalPrice = Number(
              this.$fun.ceil(Number(goodsItem.price) * goodsItem.num)
            );
            dataItem.totalPrice += Number(goodsItem.totalPrice);
            dataItem.totalNum += goodsItem.num;
            dataItem.confirm_freight += Number(goodsItem.confirm_freight);
          });
        });
        dataItem.totalPrice = this.$fun.ceil(dataItem.totalPrice);
      });
    },
    handleSizeChange(val) {
      this.form.pageSize = val;
      this.form.pageSize = 1;
      this.getData(this.form);
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getData(this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.logisticsInformationBox {
  padding: 12px 8px 5px;
  max-height: 500px;
  overflow: auto;
  display: flex;
  min-height: 50px;
  position: relative;
  &::-webkit-scrollbar {
    width: 2px;
  }

  .rightConBox {
    position: relative;
    z-index: 1;
    height: 100%;
    .leftBorderBox {
      width: 1px;
      height: 100%;
      background-image: url("../../../../assets/icon/wuliuLine.png");
      position: absolute;
      left: 16px;
    }
    .logoptBOx {
      display: flex;
      position: relative;
      z-index: 1;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
        background-color: white;
      }
      .actLogistics {
        width: 32px;
        flex: 0 0 32px;
        height: 32px;
        background-color: #2c4bdf;
        box-shadow: 0px 6px 12px 0px rgba(44, 75, 223, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 9px;
        img {
          width: 19px;
          height: 17px;
        }
      }
      .normalIcon {
        background: #ffffff;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
        img {
          width: 15px;
          height: 10px;
        }
      }
      .logisticsFontBox {
        .logisticsTitle {
          font-size: 14px;
          margin-bottom: 5px;
          line-height: 20px;
        }
        .logisticsCon {
          display: flex;
          align-items: center;
          .wuliuDateIcon {
            margin-right: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: -0.7px;
            height: 16px;
            img {
              width: 12px;
              height: 12px;
              flex: 0 0 12px;
            }
          }
          .wuliuDateFont {
            font-size: 12px;
            height: 16px;
            color: #999999;
          }
        }
      }
    }
  }
}
.logisticsnoDataBox {
  width: 100%;
  font-size: 13px;
  color: #c0c4cc;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#userOrder {
  @import "../../../../css/mixin";
  width: 100%;

  button {
    cursor: pointer;
  }
  > nav {
    height: 50px;
    display: flex;
    background-color: #f6f6f6;

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 130px;
      padding: 0 7px;
      height: 50px;
      background: #e8e8e8;
      border: 1px solid #e1e1e1;
      font-size: 12px;
      color: #000000;
      line-height: 26px;

      cursor: pointer;

      &.active {
        background-color: #ffffff;
        border-bottom: none;
      }

      .label {
        display: inline-block;
        margin-right: 7px;
      }

      .numberCount {
        width: 25px;
        height: 25px;
        background: #fbbb21;
        border-radius: 50%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 25px;
      }
    }
  }

  > div {
    // min-height: 720px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-top: none;
    padding: 20px 20px 37px 20px;
    .searchBox {
      display: flex;
      margin-bottom: 20px;
      .myInput {
        height: 40px;
        border-radius: 4px;

        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 20px;
        > i {
          width: 20px;
          height: 24px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }
        > input {
          border: none;
          border-radius: 4px;
          border: 1px solid #c0c4cc;
          width: 400px;
          height: 100%;
          font-size: 14px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;
          &:focus {
            border-color: #1e2997;
          }
        }
      }
      /deep/.el-date-editor {
        // width: 230px;
        height: 40px;
        margin-right: 20px;
        border-radius: 4px;
        .el-range-input {
          font-size: 14px;
        }
      }
      .searchBtn {
        width: 80px;
        height: 40px;
        background: $homePageSubjectColor;
        border-radius: 4px;

        color: #ffffff;
        font-size: 16px;
      }
    }
    .dataBody {
      margin-bottom: 20px;

      .myTable {
        // 设置表格所有样式
        font-size: 14px;
        color: #2f2f2f;
        line-height: 19px;
        * {
          transition: 0.3s;
        }
        li {
          flex: 1;
          // border: solid;
        }
        .flex07 {
          flex: 0.7;
        }
        .flex15 {
          flex: 1.5;
        }
        .goodsDetail {
          justify-content: flex-start;
        }
        .flex15 {
          flex: 1.5;
        }
        .ml {
          background-color: white;
        }
        ul {
          display: flex;
          height: 100px;

          li {
            background: #f9f9f9;
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-last-child(1) {
              border: 0;
            }
            img {
              cursor: pointer;
            }
          }
        }
        // 设置表头样式
        .tableHead {
          height: 56px;
          background: #f0f0f0;
          display: flex;
          align-items: center;
          text-align: center;
        }
        .bushu {
          flex: 0 0 91px;
        }

        // 设置表格内容样式
        .tableBody {
          display: block;
          .tableBodyOpt {
            // 设置表格内容里所有的单元格样式
            > div {
              display: flex;
              align-items: center;
              > div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .optTitle {
              height: 60px;
              display: flex;
            }

            .serialNumber {
              display: flex;
              height: 75px;
              // border-top: solid 1px #ededed;
              .order {
                display: flex;
                .img {
                  width: 91px;
                  text-align: center;
                  img {
                    width: 17px;
                    height: 17px;
                    cursor: pointer;

                    transform: rotate(-90deg);
                    &.openIcon {
                      transform: rotate(0deg);
                    }
                  }
                }
              }
              .order_sn {
                flex: 3.2;
                line-height: 30px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                button {
                  color: #1e2997;
                  cursor: pointer;
                }
              }
              .item {
                flex: 1;
                text-align: center;
              }
              .payBtn {
                flex: 1.5;
                span {
                  display: block;
                  height: 30px;
                  padding: 0 10px;
                  background: #2129a1;
                  border-radius: 4px;
                  line-height: 30px;
                  text-align: center;
                  color: #fff;
                  cursor: pointer;
                }
              }
              .internationalLogistics {
                flex: 1.5;
                word-break: break-all;
                //el-popover会套好几个span,所以加这句
                /deep/span {
                  width: 100%;
                }
                .expressnoCon {
                  width: 100%;
                  color: #2c4bdf;
                }
              }
            }
          }
        }
        // 单个单元格样式
        // Nº

        // 照片
        .picture {
          min-width: 100px !important;
          max-width: 100px !important;
          width: 100px !important;
        }
        // 属性
        .detail {
          max-width: 200px !important;
          min-width: 200px !important;
          width: 200px !important;
          &.body {
            overflow-y: auto;
          }

          // height: 120px;
          .detailBox {
            margin: 0 auto;
            width: 170px;
            max-height: 110px;
            min-height: 20px;
            display: flex;

            flex-direction: column;
            align-items: flex-start;
            > p {
              text-align: left;
            }
          }
        }
        .status {
          width: 250px !important;
          .gooodsQurestStatus {
            color: #ffa018;
          }
          .goGoods {
            cursor: pointer;
          }
        }

        .num,
        .price,
        .total,
        .freight,
        .remark {
          width: 100px;
          min-width: 100px !important;
          max-width: 100px !important;
        }
        .remark img {
          cursor: pointer;
        }
      }
    }
    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      > a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 2px;
      }

      /* 分页被选中的页码样式 */
      /deep/.el-pager li {
        margin: 0 3px;
        border-radius: 4px;
        box-shadow: 0px 0px 6px 0px rgba(61, 61, 61, 0.1);

        &:hover {
          color: $homePageSubjectColor;
        }

        &.active {
          background-color: $homePageSubjectColor;
          color: white;
        }
      }
    }
  }
}
.goodsDetailAll {
  max-height: 500px;
  overflow-y: auto;
  .goodsDetailAllOneBox {
    h1 {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    p {
      margin-left: 20px;
      max-width: 300px;
      margin-bottom: 10px;
    }
  }
}
</style>
